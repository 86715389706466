.catalogue-tag{
    margin: 4px;
    border-radius: 19px;
    color: rgb(255, 255, 255);
    padding: 5px 10px;
    background: #81523d;
    font-size: 10px;
}

.order-list-table{
    font-size: 13px;
}

.order-list-table td{
    font-size: 13px;
    padding: 15px;
    white-space: nowrap;
}

.order-list-table thead tr{
    font-size: 13px;
    color:black;
    background-color: lightgray;
    border-bottom: 1px solid darkgray;
    padding: 15px;
    white-space: nowrap;
}
.order-list-table thead td{
    border-right: 1px solid darkgray;
}

.order-list-table tbody tr:nth-child(odd){
    background-color: #f1f1f1;
    border-bottom: 1px dotted grey;
    
  }


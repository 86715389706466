.form-control-list{
    display: flex;
    color: rgb(68, 68, 68);
    padding: 10px;
    flex-direction: column;
    width: 100%;
}

.form-control-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 8px;    
    margin: 10px 0px;
    box-shadow: 1px 1px 4px -1px;
    border-radius: 3px;

}

.form-field-caption{
    font-size: 13px;
}

.field-editor-button {
    background: #f87a15;
    color: white;
    padding: 3px 17px;
    font-size: 10px;
    font-weight: bold;
    border-radius: 15px;
}

.field-wrapper {
    padding: 5px;
    display: flex;
    margin: 5px;
    align-items: flex-start;
    flex-direction: column;
    border: 1px solid grey;
    border-radius: 5px;
}

.stripes {
    
    /*background-image: linear-gradient(45deg, #000 25%, transparent 25%, transparent 50%, #000 50%, #000 75%, transparent 75%, #fff);
    background-size: 50px 50px;*/
    background: repeating-linear-gradient(-45deg, #444444, #444444 2px, transparent 2px, transparent 4px);
  }
.current-nav-page-button {
    font-size: 13px;
    border: 0;
    margin: 5px;
    color: inherit;
    background: #fbd9b8;
    padding: 3px 12px;
    border-radius: 20px;
    cursor: pointer;

}

.nav-page-button {
    font-size: 13px;
    border: 1px solid #fbd9b8;
    color:#fbd9b8;
    margin: 5px;
    color: inherit;
    background: white;
    padding: 3px 12px;
    border-radius: 20px;
    cursor: pointer;
   
}

.nav-page-button:hover, .current-nav-page-button:hover{
    box-shadow: 1px 1px 2px rgba(10,10,10,0.3);
}
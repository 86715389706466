.number-field-button{
    width: 24px!important;;
    height: 24px!important;;
    aspect-ratio: 1/1;
    background: #f2f2f2;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.number-field-input{
    width: 70px!important;
    height: 24px!important;
    color:black;
    font-weight: bold;
    font-size: 12px;
    background: white;
    border: 0;
    border-right:1px solid rgb(176 174 174);;
    border-left:1px solid rgb(176 174 174);;
}

.number-field-wrapper{
    display: flex;
    margin: 5px;
    width: 120px;
    align-items: flex-start;
    border: 1px solid rgb(176 174 174);
    border-radius: 5px;
    overflow: hidden;
    box-shadow: rgb(10 10 10 / 70%) 1px 1px 4px -2px;
}
.App {
 /*  text-align: center; */
  /*background-color: #3c4b52;*/
  /* background: linear-gradient(135deg, rgba(2,0,36,1) 0%, rgba(9,121,116,1) 35%, rgba(0,255,111,1) 100%);*/
} 

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.game-list-link{
  color: rgb(0 4 3);
    background-color: rgb(160 234 210);
    /* box-shadow: 2px 2px 2px rgb(100 100 100 / 50%); */
    padding: 5px 20px;
    /* font-weight: bold; */
    border-radius: 20px;
    text-decoration: none;
    font-size: 13px;
    letter-spacing: 1px;
}

.game-list-link:hover{
  background-color: rgb(5, 255, 172);
  box-shadow:0px 0px 0px 5px rgba(250,250,250,0.4);
}

.game-ok-button{
  width: 48px;
    border: 1px solid green;
    background: white;
    padding: 10px;
    margin: 0px;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 10px rgb(250 250 250 / 20%);
}


.game-cancel-button{
  width: 48px;
    border: 1px solid red;
    background: white;
    padding: 10px;
    margin: 0px;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 10px rgb(250 250 250 / 20%);
}

.app-button-primary-02{
  box-sizing: border-box;
  /* box-shadow: rgb(10 10 10 / 30%) 1px 1px 2px; */
  /* background: #FFE0B2; */
  outline: none;
  padding: 7px 17px;
  font-family: Righteous;
  font-size: 10px;
  color: #763801;
  border-radius: 20px;
  cursor: pointer;
  border: 0px solid rgb(171 83 53);
  /* box-shadow: 1px 1px 4px -1px rgba(10,10,10,0.5); */
  text-decoration: none;
  /* letter-spacing: 2px;*/
}

.app-button-primary-01 {
  box-sizing: border-box;
  /* box-shadow: rgb(10 10 10 / 30%) 1px 1px 2px; */
  background: #0a0a0a;
  outline: none;
  padding: 10px 25px;
  font-family: Righteous;
  font-size: 14px;
  color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
  border: 0px solid rgb(171 83 53);
  /* box-shadow: 1px 1px 4px -1px rgba(10,10,10,0.5); */
  text-decoration: none;
  /* letter-spacing: 2px;*/

}

.app-button-primary {
  box-sizing: border-box;
  /* box-shadow: rgb(10 10 10 / 30%) 1px 1px 2px; */
  background: #FFE0B2;
  outline: none;
  padding: 10px 25px;
  font-family: Righteous;
  font-size: 14px;
  color: #763801;
  border-radius: 3px;
  cursor: pointer;
  border: 0px solid rgb(171 83 53);
  /* box-shadow: 1px 1px 4px -1px rgba(10,10,10,0.5); */
  text-decoration: none;
  /* letter-spacing: 2px;*/

}

.header-button-primary{
  box-sizing: border-box;
    /* box-shadow: rgb(10 10 10 / 30%) 1px 1px 2px; */
    background: #ffdead;
    outline: none;
    padding: 10px 25px;
    font-family: Righteous;
    font-size: 14px;
    color: #321f02;
    border-radius: 3px;
    cursor: pointer;
    outline-color: none;
    border: 0px solid rgb(171 83 53);
    /* box-shadow: 0px 0px 1px 0px; */
    text-decoration: none;
    /* letter-spacing: 2px; */
    font-size: 12px;
    padding: 5px 12px;
    margin: 5px;
}

.app-button-primary:hover {
    box-sizing: border-box;   
    background: #ffcb7e;    
}

.app-button-secondary-small {
  font-size: 10px;
  font-weight: bold;
  margin: 5px;
  padding: 5px 14px;
  /* border: 1px solid #cecece; */
  background: #FF9800;
  border-radius: 36px;
  cursor: pointer;
  color: #ffffff;
  box-shadow: 1px 1px 4px -3px rgb(20 20 20 / 40%);
  border: 0;
  outline: none;
}

.app-button-03-small {
  font-size: 10px;
    bottom: 0px;
    background: #000000c7;
    color: white;
    padding: 3px 12px;
    border-radius: 10px;
    margin: 2px;
    letter-spacing: 1px;
    border:0;
    cursor: pointer;
}

.app-button-03-small :hover{
  font-size: 10px;
  bottom: 0px;
  background: #000000ef;
  color: white;
  padding: 3px 12px;
  border-radius: 10px;
  margin: 2px;
  letter-spacing: 1px;
}

.search-input-wide {
  box-sizing: border-box;
  /* box-shadow: rgb(10 10 10 / 50%) 2px 2px 4px; */
  padding: 5px 10px;
  outline: none;
  height: 50px;
  font-family: Righteous;
  font-size: 16px;
  color: #ab571e;
  /* border: 0px solid rgb(204, 204, 204); */
  border: 1px solid rgb(171 83 53);
  width: 100%;
  border: 0;
  background: #e9e6dd;
  border-radius: 5px;
  /* box-shadow: 2px 2px 4px -2px rgba(20,20,20,0.3);
  border: 1px #dfc9c9 solid; */
}

.search-input-filter {
  height: 50px;
  background: rgb(245 204 151);
  padding: 10px;
  font-family: Righteous;
  border: 1px solid rgb(171 83 53);
  border-radius: 0px 5px 5px 0px;
}

.item-card {
  width: 150px;
  height: 150px;
  position: relative;
  border: 0px solid green;
 /*  background: 0% 0% / 100% 100% rgb(255 203 151); */
  border-radius: 5px;
  /* filter: drop-shadow(rgba(10, 10, 10, 0.7) 2px 2px 2px); */
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
  margin: 10px;
  background: white;
  border: 0px solid rgb(171 83 53);
  box-shadow: 2px 2px 19px 0px rgb(20 20 20 / 30%);
  background: rgb(238,209,174);
background: linear-gradient(138deg, rgb(245 245 245) 0%, rgb(230 241 253) 100%);
}

.form-label{
  font-size: 12px;
  color:#9E9E9E;
}

.form-label-2
{
  font-size: 12px;
  color: #9E9E9E;
}

.form-text-input{
  padding: 13px 12px;
  font-size: 13px;
  outline: none;
  /* border: 1px solid rgb(10 10 10); */
  /* box-shadow: 1px 1px 8px -1px rgb(20 20 20 / 40%); */
  border-radius: 4px;
  margin: 10px 0px;
  border: 0;
  background: #d9d9d9;
}


.input-field{
  display: inline-flex;
  flex-direction: column;
  width: 95%; 
  max-width: 350px;
  /* margin:5px; */
}

/* .number-field-button{
  width: 24px!important;;
  height: 24px!important;;
  aspect-ratio: 1/1;
  background: #f2f2f2;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.number-field-input{
  width: 70px!important;
  height: 24px!important;
  color:black;
  font-weight: bold;
  font-size: 12px;
  background: white;
  border: 0;
  border-right:1px solid rgb(176 174 174);;
  border-left:1px solid rgb(176 174 174);;
} */

.button-group-wrapper{
  display: flex;
  margin: 5px;
  width: 120px;
  align-items: flex-start;
  border: 1px solid rgb(176 174 174);
  border-radius: 5px;
  overflow: hidden;
  box-shadow: rgb(10 10 10 / 70%) 1px 1px 4px -2px;
}

.popup-view-container
{
  max-height: 50vh;
  overflow-y: auto;
}

.popup-title
{
  padding: 15px;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 20px;
}

.account-prompt-view
{
  display: flex;
    border-radius: 10px;
    /* box-shadow: rgba(10, 10, 10, 0.2) 2px 2px 19px -6px; */
    border: 0px solid rgb(206, 206, 206);
    max-width: 400px;
    min-width: 320px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    /* padding: 30px;*/
}


@media screen and (min-width: 550px) {

  .account-prompt-view
  {
    
      box-shadow: rgba(10, 10, 10, 0.2) 2px 2px 19px -6px;
      width: 80%;
      padding: 30px;
  }
}

.invoice-wrapper
{
  display: flex;
    width: 600px;
    
    transform-origin: center top;
    transform: scale(1);
    background: white;
    margin: 60px;
    font-size: 12px;
    padding: 0px;
    border-radius: 0px;
    box-shadow: rgb(30 30 30 / 82%) 2px 2px 20px -3px;
    flex-direction: column;
}
.invoice-table thead{
    background-color: red;
}
.invoice-table {
    border-spacing: 1;
    border-collapse: collapse;
    background: white;
   /*  border-radius: 6px; */
    overflow: hidden;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  .invoice-table * {
    position: relative;
  }
  .invoice-table td, table th {
    padding-left: 8px;
  }
  .invoice-table thead tr {
    height: 50px;
    text-align: center;
    background: rgb(222 103 0);
    font-size: 14px;
    color: white;
    font-weight: normal;
  }

  .invoice-table thead tr th {
    
    text-align: center;
  }

  .invoice-table tbody tr:nth-child(odd){
    background-color: #f1f1f1;
    border-bottom: 1px dotted grey;
    
  }

  .invoice-table tbody tr td input{
   /*  background: none;
    height: 100%;
    border:0;
    border-bottom: 2px solid black;
    outline: none;
    text-align: center; */
    
  }

  .invoice-table tbody tr {
    height: 48px;
    
  }
  .invoice-table tbody tr:last-child {
    border: 0;
  }
  .invoice-table td, .invoice-table th {
    text-align: left;
  }
  .invoice-table td.l, .invoice-table th.l {
    text-align: right;
  }
  .invoice-table td.c, .invoice-table th.c {
    text-align: center;
  }
  .invoice-table td.r, .invoice-table th.r {
    text-align: center;
  }

  .invoice-table tfoot tr {
    background-color: #f6e5d6;
    border-bottom: 0px solid #565656;
  }

  .row-delete-button {
    background: #ffcbcb;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 1px;
    margin: 5px;
    cursor: pointer;
    aspect-ratio: 1;
  }

  .zigzag {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 40px;
    background: #f6e5d6;
    min-width: 400px;
    max-width: 800px;
    margin-bottom: 15px;
  }
  .zigzag::before {
    position: absolute;
    bottom: -15px;
    width: 100%;
    height: 15px;
    background-image: linear-gradient(-45deg, #f6e5d6 50%, transparent 50%),
              linear-gradient(45deg, #f6e5d6 50%, transparent 50%);
    background-size: 20px 20px;
    content: '';
    transform:rotateX(180deg);
  }
  
  .invoice-view-menu-button
  {
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    border: 0;
    background: #efdca4;
    padding: 5px;
    border-radius: 50%;
    box-shadow: 1px 1px 3px -1px;
    cursor: pointer;


  }
 /*  @media screen and (max-width: 35.5em) {
    table {
      display: block;
    }
    table > *, table tr, table td, table th {
      display: block;
    }
    table thead {
      display: none;
    }
    table tbody tr {
      height: auto;
      padding: 8px 0;
    }
    table tbody tr td {
      padding-left: 45%;
      margin-bottom: 12px;
    }
    table tbody tr td:last-child {
      margin-bottom: 0;
    }
    table tbody tr td:before {
      position: absolute;
      font-weight: 700;
      width: 40%;
      left: 10px;
      top: 0;
    }
} */
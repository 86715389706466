.tabbed-view-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 250px;;
    display: flex;
    align-items: center;
  }
  
  .tabbed-view-container > div {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    will-change: transform;
  }
  
  .hand-pointer{
    cursor: pointer;
  }

  .tabbed-view-container > div > div {
    touch-action: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%;
    will-change: transform;
    /*box-shadow: 0 62.5px 125px -25px rgba(50, 50, 73, 0.5), 0 37.5px 75px -37.5px rgba(0, 0, 0, 0.6);*/
  }
  
  .navigation-button {
    position: absolute;    
    width: 40px;
    top: 50%;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .navigation-button:hover{  
    width: 50px;
  }

  .right-navigation{
    right: 0px;
    border-radius: 5px 0px 0px 5px;
  }

  .left-navigation{
    left: 0px;
    border-radius: 0px 5px 5px 0px;
  }

  .tabbed-view-active-button
  {
    /* padding: 10px;
    border: 0;
    border-bottom: 5px #FF9800 solid;
    background: #ffe0b2;
    font-weight: bold;
    border-radius: 5px 5px 0px 0px;
    margin: 0px 2px;
    cursor: pointer; */
    padding: 10px 20px;
    border: 0;
    /* border-bottom: 5px #FF9800 solid; */
    background: #ffe0b2;
    font-weight: bold;
    border-radius: 25px;
    margin: 2px 4px;
    cursor: pointer;
    font-size: 11px;
  }
  

  .tabbed-view-inactive-button
  {
    /* padding: 10px;
    border: 0;
    border-bottom: 5px #e0deda solid;
    background: #f8f4f0;
    font-weight: bold;
    border-radius: 5px 5px 0px 0px;
    margin: 0px 2px;
    cursor: pointer; */
    padding: 10px 20px;
    border: 0;
    /* border-bottom: 5px #e0deda solid; */
    background: #f8f4f0;
    font-weight: bold;
    border-radius: 25px;
    margin: 2px 4px;
    cursor: pointer;
    font-size: 11px;
  }
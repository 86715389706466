@keyframes openAnimation{
    from {max-width: 0%; display: flex;}
    to {max-width: 100%;display: flex;}
}

@keyframes closeAnimation{    
    0% {max-width: 250px;opacity: 1;display: flex;}
    95% {max-width: 50px;opacity: 0;display: flex;}
    100% {max-width: 50px; opacity: 0;display: none;}
}

.side-menu-container{
    /*padding: 10px;*/
    /*min-width: 150px;*/
    max-width: 250px;
    width:100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: fixed;
     /*background-color: rgba(255, 255, 255, 0.9);
    border-right: 1px solid rgb(206, 206, 206); */
    left: 0px;
    top: 60px;
    bottom: 0px;
    /*box-shadow: 2px 0px 4px 0px rgb(27 26 26 / 35%);*/
    box-sizing: border-box;
}

.side-menu-close{
    /*display: none;*/
    animation-name: closeAnimation;
    animation-duration: 0.5s;
    display: none;
}

.side-menu-open{
    display: flex;
    animation-name: openAnimation;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
}

.side-menu-backdrop{
    display:block;
    background-color: rgba(20, 20, 20, 0.4);
    backdrop-filter: blur(3px);
    position: fixed;
    top: 0px;
    width: 100vw;
    height: 100vh;
}

.side-menu-backdrop-show{
    display:block;
}

.side-menu-backdrop-hide{
    display:none;
}

@media screen and (min-width: 550px) {
    .side-menu-container {
        display: flex;
       /*  box-shadow:none; */
       
    }

    /*.side-menu-backdrop{
        display:none;
    }*/

    .side-menu-backdrop-show{
        display:none;
    }

    .side-menu-close{       
        display: flex;
        animation: none;
    }

    .side-menu-open{
        display: flex;
        animation: none;
    }
}


@media screen and (max-width: 550px) {
    .side-menu-container{
        top: 60px;
        max-width: 100%;
        background-color: white;
      
    }
}

.side-menu-links-container {
    list-style-type:none;
    /*padding:5px;*/
    padding-left:0px;
    margin: 0;
}

.side-menu-category-label {
    font-size: 16px;
    /* font-weight: bold;
    color:#333333; */
   /*  margin-top: 5px; */  
    padding: 10px 10px;  
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    cursor:pointer;
}



.side-menu-expand-icon {
    width:16px;
    height:16px;
    /* background-image: url("../../assets/images/expand-icon.png"); */
    background-repeat: no-repeat;  
}

.side-menu-category-label-active{    
    font-weight: normal;
    box-sizing: border-box;
    width: 100%;
    /* border-bottom: 1px solid #ab5335;
    border-top: 1px solid #ab5335;
    background-color: #ffcb97;
    background: linear-gradient(
135deg
, rgb(150, 251, 196) 0%, rgb(249, 245, 134) 100%); */
    padding: 5px 10px;
    color: #ab5335;
    background-color: #fcd8b5;
   
    border-radius: 35px;

    font-weight: normal;
    box-sizing: border-box;
    width: 100%;
    padding: 5px 10px;
    color: #ef6c00;
    background-color: #f8e9da;
   /*  border-radius: 9px; 
    box-shadow: 1px 1px 1px;*/
}

.side-menu-category-label-inactive{   
    
    box-sizing: border-box;
    width: 100%;
    color:#7b7b7b;
    /* background-color: #21383a; */
    padding: 5px 10px;
    /*border-radius: 5px;*/
}

.side-menu-category-label-inactive:hover{   
    
    width: 100%;
    background-color: #fcd8b5;
    padding: 5px 10px;
    
    border-radius: 35px;
}

.side-menu-links-item {
    box-sizing: border-box;
    width:100%;
    color:#222222;
    padding: 5px 20px;
    margin: 0;
    cursor: pointer;
}

.side-menu-links-item:hover{
    background:#EEEEEE;
    color:#222222; 
    font-weight:bold;
}

.side-menu-container-link {
    display: block;
    text-decoration: none;
    color: #464646;
    vertical-align: middle;
    /*padding: 5px 2px 5px 4px;
    margin: 0 -2px 0 -4px;
    position: relative;*/
    font-size:14px;

}

.side-menu-container-link:hover {
    /*color:#222222;*/
}

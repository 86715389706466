.data-widget-label{
    font-size: 12px;
    padding: 5px;
    color: darkgray
}
.data-widget-caption{
    font-size: 12px;
    padding: 5px;
    color: darkgray
}

.data-widget-data{
    font-weight: bold;
    font-size: 24px;
    padding: 5px;
    color: darkorange;
}

.data-widget{
    background: rgb(238 238 238);
    /* border-left: 2px orange solid; */
    display: flex;
    flex-direction: column;
    min-height: 80px;
    padding: 5px;
    /* box-shadow: 1px 1px 5px -2px rgb(30 30 30 / 40%); */
    justify-content: space-between;
    align-items: center;
}

.data-widget-container{
    width: 100%;
    display: inline-grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.statistics-summary-info-banner{
    color:#ff8c00;
    padding: 20px;
    /* background: #ff8c00; */
    border-radius: 10px;
    display: flex;
    flex-direction:row;
    justify-content: space-between;
}


.centered-view {
    /*min-height: calc(100vh - 100px);*/
    min-height: calc(100vh);
    top: 100px;
    padding-top: 50px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    display: flex;
    box-sizing: border-box;
    /*background: linear-gradient(40deg, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);*/
    /*background:tomato;*/
    /* background:#0d0e0e; */
    background-size: 100%;
    /*position: relative;*/
}

.offset-centered-view{
    padding-left:0px;
}
.counter-offset-centered-view{
    padding-left:0px;
 }

@media screen and (min-width: 550px) {
    /*.centered-view{
        padding-left:250px;
    }*/
    
    .offset-centered-view{
       padding-left:250px;
    }
    .counter-offset-centered-view{
        padding-left:-250px;
     }
}

@media screen and (max-width: 550px) {
    .centered-view {
        top: 50px;
        padding-top: 50px;
    }
}

.form-field-wrapper{
    width: 100%;
    display: inline-grid;
    grid-template-columns: repeat(1, minmax(220px, 1fr));
    max-width:600px;
}

@media screen and (min-width: 750px) {    
    .form-field-wrapper{
        width: 100%;
        display: inline-grid;
        grid-template-columns: repeat(2, minmax(220px, 1fr));
        max-width:600px;
    }
}